import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useAinesPage } from "../services/firestore";
import { Highlight, HighlightedImages } from '../components/Highlight'

const AinesPage = ({ data }) => {
  const page = useAinesPage(data.allAinesPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Ainés"}>
      {page && <Highlight title={page.title} text={page.highlight} />}

      {page && (
        <HighlightedImages
          size="XL"
          images={[{image: page.image}]}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    allAinesPages {
      nodes {
        title
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        image {
          src
          image
        }
      }
    }
  }
`;

export default AinesPage;
